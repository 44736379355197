<script lang="ts" setup>
import type { JackpotValue } from "@/types";

const { t } = useT();

const props = defineProps<{ jackpot: JackpotValue[number]; isSweepStakes: boolean }>();

const { data: appInit } = useAppInitData();
const ANIMATION_TIME_PARAMS: Record<
	number,
	{ class: string; interval: number; increment: number; transition: number }
> = {
	1: {
		class: "red",
		interval: 550,
		increment: 0.01,
		transition: 500
	},
	2: {
		class: "silver",
		interval: 550,
		increment: 0.05,
		transition: 500
	},
	3: {
		class: "gold",
		interval: 550,
		increment: 0.1,
		transition: 500
	},
	4: {
		class: "diamond",
		interval: 550,
		increment: 0.15,
		transition: 500
	}
} as const;

// TODO - Add dynamic data
const JACKPOT_LAST_WIN = {
	RED: {
		winners: 715,
		maximum: 2248,
		maximumLabel: "Stefanie L",
		maximumLocation: "Little Rock, AR",
		last: 1972,
		lastLabel: "Stefanie L",
		lastLocation: "Little Rock, AR"
	},
	SILVER: {
		winners: 112,
		maximum: 11965,
		maximumLabel: "Jordan R",
		maximumLocation: "Ruckersville, VA",
		last: 11928,
		lastLabel: "Nancy R",
		lastLocation: "Salt Lake City, UT"
	},
	GOLD: {
		winners: 3,
		maximum: 152664,
		maximumLabel: "Kymberly T",
		maximumLocation: "Huntington Beach, CA",
		last: 116802,
		lastLabel: "Brian K",
		lastLocation: "Shawnee, KS"
	},
	DIAMOND: {
		winners: 1,
		maximum: 135600802,
		maximumLabel: "Mike E",
		maximumLocation: "Tusla, AZ",
		last: 135600802,
		lastLabel: "Mike E",
		lastLocation: "Tusla, AZ"
	}
};

const item = ref<NonNullable<JackpotValue[number]>>({ ...props.jackpot });
const interval = ref(ANIMATION_TIME_PARAMS[item.value.id].interval);
const incVal = ref<number>(ANIMATION_TIME_PARAMS[item.value.id].increment);
const oldAmount = ref(0);
const gameMode = computed(() => appInit.value?.gameMode);
const loggedIn = computed(() => !appInit.value?.isGuest);
const isTournament = computed(() => gameMode.value === "TournamentPoints" || !loggedIn.value);
const valueObj = ref();

const formatNumber = (value: number, isFloat: boolean) => {
	if (value == null) {
		return "";
	}
	const sum = value.toString().replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1,");
	if (isFloat && !sum.includes(".")) {
		return !isTournament.value ? `${sum}.00` : sum;
	}
	if (sum.length - 1 - sum.indexOf(".") === 1) {
		return !isTournament.value ? `${sum}0` : sum;
	}
	return sum;
};

const valueToArray = () => {
	const intToStrArr = formatNumber(+(item.value.amount * 100).toFixed(0), true).split("");
	const dotIndex = intToStrArr.indexOf(".");
	const integer = intToStrArr.slice(0, dotIndex);
	if (isTournament.value) {
		valueObj.value = {
			intToStrArr
		};
	} else {
		valueObj.value = {
			integer
		};
	}
};
const incrementValue = () => {
	item.value.amount = item.value.amount + incVal.value;
	valueToArray();
};

const timer = ref();

const incrementValueInterval = () => {
	timer.value = setInterval(incrementValue, interval.value);
};

const popup = ref();

const details = ref(false);

onClickOutside(popup, () => (details.value = false));

valueToArray();

const setIncVal = () => {
	const val = (item.value.amount - oldAmount.value) / 20;
	if (val > 0) {
		incVal.value = val;
	}
};

watch(
	() => item,
	() => {
		valueToArray();
		setIncVal();
		oldAmount.value = item.value.amount;
	}
);

watch(
	() => props.jackpot,
	(value) => {
		item.value = value;
		interval.value = ANIMATION_TIME_PARAMS[value.id].interval;
		incVal.value = ANIMATION_TIME_PARAMS[value.id].increment;
		oldAmount.value = item.value.amount;
	}
);

onMounted(() => {
	incrementValueInterval();
});

onUnmounted(() => {
	window.clearInterval(timer.value);
});
</script>

<template>
	<div class="jp-counter" :class="isSweepStakes && 'jp-list-item--sweep'">
		<div class="jp-counter__wrapper">
			<AText type="base" :modifiers="['bold', 'uppercase']" class="jp-counter__title"
				>{{ item.title }} {{ t("jackpot") }}</AText
			>
			<div class="jp-counter__amount" :class="[{ 'jp-counter__amount--sweep': isSweepStakes }]">
				<ASvg :name="isSweepStakes ? '12/secret-coins' : '12/coins'" class="icon" />
				<template v-if="isTournament">
					<transition-group name="jp-counter">
						<AText
							v-for="(strArr, index) in valueObj.intToStrArr"
							:key="strArr + index"
							type="h3"
							class="jp-counter__item"
							:class="[{ 'jp-counter__item--not-a-number': !(parseInt(strArr) >= 0) }]"
							:data-text="strArr"
						>
							{{ strArr }}
						</AText>
					</transition-group>
				</template>
				<template v-else>
					<transition-group name="jp-counter">
						<AText
							v-for="(int, index) in valueObj.integer"
							:key="`${int}${index}`"
							type="h3"
							class="jp-counter__item"
							:class="[{ 'jp-counter__item--not-a-number': !(parseInt(int) >= 0) }]"
							:data-text="int"
						>
							{{ int }}
						</AText>
					</transition-group>
				</template>
			</div>
			<AText
				ref="popup"
				type="small"
				:modifiers="['underline']"
				class="jp-counter__item-text"
				@click="details = true"
				>{{ t("Details") }}</AText
			>

			<div v-if="details" class="jp-counter-details">
				<div
					class="jp-counter-details__title jp-counter-details__title--gradientful"
					:class="'jp-counter-details__title--' + item.title?.toLowerCase()"
				>
					{{ t(item.title) }} {{ t("jackpot") }}
				</div>
				<div class="jp-counter-details__item">
					<div class="jp-counter-details__label">
						<div class="jp-counter-details__title">
							{{ t("Total winners") }}
						</div>
					</div>
					<AText variant="tempe" class="jp-counter-details__val">{{
						JACKPOT_LAST_WIN[item.title as keyof typeof JACKPOT_LAST_WIN].winners
					}}</AText>
				</div>
				<div class="jp-counter-details__item">
					<div class="jp-counter-details__label">
						<div class="jp-counter-details__title">{{ t("Maximum win") }}</div>
						<AText type="small"
							>{{ JACKPOT_LAST_WIN[item.title as keyof typeof JACKPOT_LAST_WIN].maximumLabel }}
							<AText type="small jp-counter-details__location">{{
								JACKPOT_LAST_WIN[item.title as keyof typeof JACKPOT_LAST_WIN].maximumLocation
							}}</AText>
						</AText>
					</div>
					<AText type="small" class="jp-counter-details__val">{{
						numberFormat(JACKPOT_LAST_WIN[item.title as keyof typeof JACKPOT_LAST_WIN].maximum)
					}}</AText>
				</div>
				<div class="jp-counter-details__item">
					<div class="jp-counter-details__label">
						<div class="jp-counter-details__title">{{ t("Last win") }}</div>
						<AText type="small"
							>{{ JACKPOT_LAST_WIN[item.title as keyof typeof JACKPOT_LAST_WIN].lastLabel }}
							<AText type="small" class="jp-counter-details__location">{{
								JACKPOT_LAST_WIN[item.title as keyof typeof JACKPOT_LAST_WIN].lastLocation
							}}</AText>
						</AText>
					</div>
					<AText type="small" class="jp-counter-details__val">{{
						numberFormat(JACKPOT_LAST_WIN[item.title as keyof typeof JACKPOT_LAST_WIN].last)
					}}</AText>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@keyframes to-top {
	0% {
		opacity: 0;
		bottom: 0;
	}

	100% {
		opacity: 1;
		bottom: 20px;
	}
}

.jp-counter {
	&__wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		position: relative;
		gap: 4px;
	}

	&__item {
		display: inline-block;
		vertical-align: top;
		position: relative;
		text-align: center;
		transition: all 0.55s;
		transition-property: opacity, transform;
		width: 20px;
		padding: 0 2px;

		&.jp-counter__item--jp-page {
			width: 18px;

			@include media-breakpoint-down(md) {
				width: 14px;
			}
		}

		&--not-a-number {
			width: auto !important;
		}
	}

	&__title {
		color: inherit;
	}

	&__amount {
		overflow: hidden;
		position: relative;
		color: inherit;
		white-space: nowrap;
	}

	&-details {
		position: absolute;
		left: -35px;
		z-index: 2;
		width: 305px;
		background: var(--neutral);
		color: var(--gray-900);
		box-shadow: 0px 8px 24px 0px #4b1e89;
		border-radius: 5px;
		bottom: 0;
		animation: to-top 0.7s forwards;
		padding: 8px;

		&__title {
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			text-transform: uppercase;

			&--gradientful {
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			&--diamond {
				background-image: var(--gradient-1);
			}
			&--gold {
				background-image: var(--gradient-2);
			}
			&--silver {
				background-image: var(--gradient-4);
			}
			&--red {
				background-image: var(--gradient-5);
			}
		}

		&__item {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			padding: gutter(1) 0;
			border-bottom: 1px solid var(--gray-100);

			&:last-child {
				border-bottom: none;
				padding-bottom: 0;
			}
		}

		&__label {
			display: flex;
			flex-direction: column;
			gap: 4px;
			font-style: normal;
			font-size: 14px;
		}

		&__title {
			text-transform: uppercase;
			font-weight: 600;
		}

		&__location {
			color: var(--gray-500);
			margin-left: 4px;
		}

		&__val {
			color: var(--gray-950);
		}
	}

	&__dot {
		display: inline-block;
	}

	&-enter-from,
	&-leave-to {
		opacity: 0;
	}

	&-enter-from {
		transform: translateY(40px);
		margin: 0;
	}

	&-leave-to {
		transform: translateY(-40px);
	}

	&-leave-active {
		position: absolute;
	}

	&__item-img {
		height: 36px;
		margin-right: 4px;
	}

	&__item-text {
		color: inherit;
		margin-top: auto;
		width: max-content;

		&:hover {
			cursor: pointer;
			text-decoration-line: none;
		}
	}

	.icon {
		font-size: 32px;
		margin-right: 4px;
	}

	.icon-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1;
		gap: 2px;
	}
}
</style>
